import Vue from 'vue'
import Router from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import store from '@/store'
import { config } from '@/config'
import { getPageTitle } from '@/utils/helper'
import Permission from '@/cores/Permission'
import topLeftContent from '@/router/topLeftContent'
import TopLeftContentLayout from '@/views/layout/topLeftContent'
import { setPermission } from '@/cores/RouteAndPermission'
import xieyi from '@/views/login/register/xieyi'
import { clearMessagePush } from '@/api/customerpond'
import { currentVersion } from '@/api/thread'
import cookie from 'js-cookie'
Vue.use(Router)

const router = new Router({
  routes: [
    // 通用布局
    {
      path: '/',
      children: topLeftContent,
      component: TopLeftContentLayout
    },
    {
      path: '/login',
      component: () => import('@/views/login/index'),
      hidden: true,
      meta: {
        title: '登录'
      }
    },

    {
      path: '/hx-login',
      component: () => import('@/views/login/hx-login'),
      hidden: true,
      meta: {
        title: 'hx登录'
      }
    },
    {
      path: '/wx/login',
      name: 'weixinLogin',
      component: () => import('@/views/login/weixinLogin'),
      meta: {
        title: '微信登录'
      }
    },
    {
      path: '/ddscan-login',
      component: () => import('@/views/login/ddScanLogin'),
      hidden: true,
      meta: {
        title: '钉钉扫码登录'
      }
    },
    {
      path: '/dingding-login',
      component: () => import('@/views/dingdingAuth/ddLogin'),
      hidden: true
    },
    {
      path: '/dd-scrm-login',
      component: () => import('@/views/dingdingAuth/ddScrmLogin'),
      hidden: true
    },
    {
      path: '/feishu-login',
      component: () => import('@/views/feishu/login'),
      hidden: true
    },
    {
      path: '/404',
      component: () => import('@/views/404'),
      hidden: true
    },
    {
      path: '/resetPwd',
      component: () => import('@/views/login/password/resetPwd'),
      hidden: true
    },
    {
      path: '/updatePwd',
      name: '/updatePwd',
      component: () => import('@/views/login/password/updatePwd'),
      hidden: true
    },
    {
      path: '/backName',
      name: '/backName',
      component: () => import('@/views/login/password/backName'),
      hidden: true
    },
    {
      path: '/registerBase',
      name: 'registerBase',
      component: () => import('@/views/login/register/register'),
      hidden: true,
      meta: {
        title: '注册'
      }
    },
    {
      path: '/registerBaseCustomerFirst',
      name: 'registerBaseCustomerFirst',
      component: () => import('@/views/login/register/registerByAdmin'),
      hidden: true
    },
    {
      path: '/registerbusiness',
      component: () => import('@/views/login/register/registerbusiness'),
      hidden: true
    },
    {
      path: '/registercustomer',
      component: () => import('@/views/login/register/registercustomer'),
      hidden: true
    },
    {
      path: '/buypay',
      name: 'BuyPay',
      component: () => import('@/views/buypay/index'),
      hidden: true
    },
    {
      path: '/result',
      name: 'result',
      component: () => import('@/views/buypay/result'),
      hidden: true
    },
    {
      path: '*',
      redirect: '/404',
      hidden: true,
      meta: {
        title: '404'
      }
    },
    {
      path: '/organization/view',
      name: 'organization',
      component: () => import('@/views/organization/index'),
      hidden: true,
      meta: {
        title: '组织架构'
      }
    },

    {
      path: '/role',
      name: 'xieyi',
      component: xieyi,
      hidden: true,
      meta: {
        title: '用户协议'
      }
    }
  ]
})

const whiteList = config.whiteList

router.beforeEach(async (to, from, next) => {
  const env = store.state.plugin.env
  const isLogin = store.state.permission.isLogin
  const isFresh = store.state.permission.isRefreshAuth
  console.log(isFresh, 'isFresh')
  NProgress.start()
  if (to.path === '/insight/messages') {
    clearMessagePush().then()
  }

  if (from.path !== '/' && from.path !== '/keydetail/keydetail') {
    localStorage.setItem('isPreview', from.path === '/preview/list')
  }

  if (isLogin && to.path === '/dd-scrm-login') {
    next('/customerpond/customerpond')
    return
  }

  if (env === 'dd' && to.path !== '/dingding-login' && !isLogin) {
    const url = window.location.hash
    let corpId = url.split('=')[1]
    next(`/dingding-login?cid=${corpId}&from=${to.path}`)
    return
  }

  if (
    getQueryVariable2('code') &&
    to.path !== '/ddscan-login' &&
    to.path !== '/feishu-login' &&
    !isLogin
  ) {
    next('/ddscan-login?code=' + getQueryVariable2('code'))
    return
  }

  // 未登录 强制跳转
  if (
    !whiteList.includes(to.path) &&
    !isLogin &&
    env !== 'dd' &&
    env !== 'tt'
  ) {
    router.replace('/login')
    return
  }

  if (
    store.getters.registStep == 1 &&
    to.path !== '/registerbusiness' &&
    env !== 'dd' &&
    env !== 'tt'
  ) {
    next({ path: '/registerbusiness' })
    return
  }

  // 白名单内的路由不进行权限判断
  if (!whiteList.includes(to.path) && isFresh) {
    // 获取菜单栏路由和页面内容按钮
    await setPermission()
    const permissionObj = store.state.permission.permission
    const menuList = store.state.permission.menuList
    const panelList = store.state.permission.panelList
    localStorage.setItem('permissionObj', JSON.stringify(permissionObj))
    localStorage.setItem('menuList', JSON.stringify(menuList))
    localStorage.setItem('panelList', JSON.stringify(panelList))
  }

  // 防止用户通过手动输入路由访问没有权限的页面
  console.log(to.meta, '检查权限')
  if (to.meta.permission && typeof to.meta.permission === 'string') {
    Permission.checkPermission({
      condition: 'permission',
      permission: to.meta.permission
    })
  }

  if (to.meta.permissionAnd && typeof to.meta.permissionAnd === 'object') {
    Permission.checkPermission({
      condition: 'permissionAnd',
      permission: to.meta.permissionAnd
    })
  }

  if (to.meta.permissionOr && typeof to.meta.permissionOr === 'object') {
    Permission.checkPermission({
      condition: 'permissionOr',
      permission: to.meta.permissionOr
    })
  }
  document.title = getPageTitle(to.meta.title)
  next()
})

// 保存path
router.afterEach(async (to, from) => {
  const token = store.state.permission.token
  // 版本更新 手动清除缓存
  if (token) {
    const versionRes = await currentVersion()
    if (versionRes.code === 60000) {
      let vueVersion = versionRes.data
      if (cookie.get('vueVersion') != vueVersion) {
        window.location.reload()
      }
      cookie.set('vueVersion', vueVersion)
    }
  }

  if (to.path !== '/keydetail/keydetail' && from.path === '/globalquery/map') {
    sessionStorage.removeItem('mapParams')
  }

  // 添加百度统计
  if (_hmt) {
    _hmt.push(['_trackPageview', `${to.path}?title=${to.meta.title}`])
  }

  NProgress.done()
  const beforePath = from.fullPath
  if (beforePath !== '/') {
    localStorage.setItem('beforePath', from.fullPath)
  }
})

function getQueryVariable2(variable) {
  let query = location.search.split('?')
  if (query.length !== 2) return false
  let vars = query[1].split('&')
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=')
    if (pair[0] === variable) {
      return pair[1]
    }
  }
  return false
}

export default router
