import Vue from 'vue'
import { Loading, Message } from 'element-ui'
import defaultSettings from '@/settings'
import tip from '@/views/insight/tip'
import router from '@/router'
import _ from 'lodash'
import Store from '@/store'

import http from '@/_dtbird_api/http'

export const afterDate = str => {
  const date = new Date()
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()

  if (str == '一个月') {
    month += 1
    if (month > 12) {
      month -= 12
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return `${year + 1}-${month}-${day}`
    }
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return `${year}-${month}-${day}`
  }
  if (str == '三个月') {
    month += 3
    if (month > 12) {
      month -= 12
      if (month < 10) {
        month = '0' + month
      }
      if (day < 10) {
        day = '0' + day
      }
      return `${year + 1}-${month}-${day}`
    }
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return `${year}-${month}-${day}`
  }
  if (str == '一年') {
    if (month < 10) {
      month = '0' + month
    }
    if (day < 10) {
      day = '0' + day
    }
    return `${year + 1}-${month}-${day}`
  }
}

export const formatTime = str => {
  const date = new Date()
  const year = date.getFullYear()
  let month = date.getMonth() + 1
  let day = date.getDate()
  if (month < 10) {
    month = '0' + month
  }
  if (day < 10) {
    day = '0' + day
  }
  const currentDay = `${year}-${month}-${day}`
  if (str === '今天') {
    return [currentDay, currentDay]
  }
  if (str === '近7天') {
    const dateList = [...Array(7).keys()].map(days => {
      let t = new Date(Date.now() - 86400000 * days)
      let str = `${t.getFullYear()}-${t.getMonth() + 1}-${t.getDate()}`
      return str
    })
    return [dateList[6], dateList[0]]
  }
  if (str === '近30天' || str === '近一个月') {
    month -= 1
    if (month <= 0) {
      month += 12
      return [`${year - 1}-${month}-${day}`, currentDay]
    }
    return [`${year}-${month}-${day}`, currentDay]
  }
  if (str == '近三个月' || str == '近90天') {
    month -= 3
    if (month <= 0) {
      month += 12
      return [`${year - 1}-${month}-${day}`, currentDay]
    }
    return [`${year}-${month}-${day}`, currentDay]
  }
  if (str == '最近半年') {
    month -= 6
    if (month <= 0) {
      month += 12
      return [`${year - 1}-${month}-${day}`, currentDay]
    }
    return [`${year}-${month}-${day}`, currentDay]
  }
  if (str == '最近一年') {
    return [`${year - 1}-${month}-${day}`, currentDay]
  }
  if (str == '最近三年') {
    return [`${year - 3}-${month}-${day}`, currentDay]
  }
  if (str == '全部' || str == '不限') {
    return []
  }
  if (str == '本年') {
    return [`${year}-01-01`]
  }
  return [`${str}-01-01`, `${str}-12-31`]
}

export const handelDateFn = num => {
  //   case 'TODAY':
  //     this.getHandelData(0)
  // case 'WEEK':
  //     this.getHandelData(6)
  // case 'MONTH':
  //     this.getHandelData(29)

  var date2 = new Date(new Date())
  // num 为当前传入入参，-为之前  +为之后 0为当日
  date2.setDate(new Date().getDate() - num)
  // 开始时间
  let month2 = date2.getMonth() + 1
  let day2 = date2.getMonth()
  month2 = month2 < 0 ? '0' + month2 : month2
  day2 = day2 < 0 ? '0' + day2 : day2
  const startTimeData = date2.getFullYear() + '-' + month2 + '-' + day2

  // 结束时间
  const endTimeData =
    new Date().getFullYear() +
    '-' +
    (new Date().getMonth() + 1) +
    '-' +
    new Date().getDate()
  return [startTimeData, endTimeData]
}

export const flatTime = str => {
  // debugger
  const timeList = str.split('-')
  const year = timeList[0]
  let month = timeList[1]
  let day = timeList[2]
  if (month.length == 1 && month < 10) {
    month = '0' + month
  }
  if (day.length == 1 && day < 10) {
    day = '0' + day
  }
  return `${year}-${month}-${day}`
}

//sort排序默认高亮
export const initSortClass = (dom, index, className = 'ascending') => {
  let nodeList = []
  Vue.nextTick(() => {
    nodeList = Array.from(
      dom
        ?.querySelector('.el-table__header-wrapper')
        ?.querySelector('.el-table__header')
        ?.querySelector('.has-gutter')
        ?.querySelector('tr').childNodes || []
    )
    nodeList[index].className = className
  })
}

export const timeout = time => {
  return new Promise((resolve, reject) => {
    window.setTimeout(() => {
      resolve()
    }, time)
  })
}

export const isJSON = str => {
  try {
    if (typeof str == 'object') {
      JSON.stringify(str)
      return true
    } else {
      return false
    }
  } catch (e) {
    return false
  }
}

export const debounceFn = (fn, wait = 400) => {
  let timer = null
  return function() {
    let context = this
    let args = arguments
    if (timer) {
      clearTimeout(timer)
      timer = null
    }
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, wait)
  }
}

//千分位数字展示
export const formatRegex = (number = 0) => {
  if (!number) {
    return 0
  }
  return !(number + '').includes('.')
    ? // 就是说1-3位后面一定要匹配3位
      (number + '').replace(/\d{1,3}(?=(\d{3})+$)/g, match => {
        return match + ','
      })
    : (number + '').replace(/\d{1,3}(?=(\d{3})+(\.))/g, match => {
        return match + ','
      })
}

//数据结构判断
export const getDataType = str => {
  const strType = Object.prototype.toString.call(str)
  const res = strType.replace(/\[|\]/g, '').split(' ')[1]
  const ret = res.charAt(0).toLowerCase() + res.substring(1)
  return ret
}

export const loadTime = () => {
  const loading = Loading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)'
  })
  return loading
}

//智能决策ID
export const insightId = () => {
  return [160, 165, 210, 220, 230]
}

//根据后端返回的id进行权限分类
export const configPermissionId = (list, index) => {
  const dataHow = insightId() //智能决策
  const tuoKe = [1, 10, 60] //拓客
  const phoneSell = [1, 5, 503, 504] //电销
  const privacyData = [60] //私域
  const customerData = [20] //客户
  const analyzeData = [40, 140, 143, 146, 150] //分析
  const otherData = [10, 30, 50, 80, 90, 100, , 120, 130, 155] //其他
  //全部数据
  if (index == -1) {
    return list
  }
  //智能决策
  if (index == 0) {
    return list.filter(item => {
      return dataHow.includes(item.id)
    })
  }
  //拓客
  if (index == 1) {
    return list.filter(item => {
      return tuoKe.includes(item.id)
    })
  }
  //电销
  if (index == 2) {
    return list.filter(item => {
      return phoneSell.includes(item.id)
    })
  }
  //私域
  if (index == 3) {
    return list.filter(item => {
      return privacyData.includes(item.id)
    })
  }
  //客户
  if (index == 4) {
    return list.filter(item => {
      return customerData.includes(item.id)
    })
  }
  //分析
  if (index == 5) {
    return list.filter(item => {
      return analyzeData.includes(item.id)
    })
  }
  //其他
  if (index == 6) {
    return list.filter(item => {
      return otherData.includes(item.id)
    })
  }
}

//客户模块根据权限显示对应tab
//path==costomerpond客户公海进入

export const customerPermission = (type, name, path = 'costomerpond') => {
  if (type == 1) {
    return `客户管理CRM-客户公海-我负责的-${name}`
  }
  if (type == 2) {
    if (path != 'costomerpond') {
      return `客户管理CRM-客户公海-全公司的-${name}`
    }
    return `客户管理CRM-客户公海-可跟进的-${name}`
  }
  if (type == 3) {
    if (path != 'costomerpond') {
      return `客户管理CRM-客户公海-可跟进的-${name}`
    }
    return `客户管理CRM-客户公海-全公司的-${name}`
  }
  if (type == 4) {
    return `客户管理CRM-客户公海-草稿箱-${name}`
  }
  if (type == 5) {
    return `客户管理CRM-客户公海-我协作的-${name}`
  }
  return false
}

export const onlyUuid = () => {
  let temp_url = URL.createObjectURL(new Blob())
  let uuid = temp_url.toString()
  URL.revokeObjectURL(temp_url)
  const result = uuid.substring(uuid.lastIndexOf('/') + 1)
  return result
}

export const withOutAccess = title => {
  Store.dispatch('showModal', {
    title: '提示',
    view: tip,
    options: {
      title
    },
    size: '600px'
  })
}

export const noRepeat = arr => {
  return [...new Set(arr)]
}

export const objGroup = data => {
  let dataInfo = {}
  data.forEach(item => {
    let { key, name, type } = item
    if (!dataInfo[key]) {
      dataInfo[key] = {
        key,
        child: []
      }
    }
    dataInfo[key].name = name
    dataInfo[key].type = type
    if (type == 'custom') {
      dataInfo[key].child.push(item.name)
    } else {
      dataInfo[key].child.push(item.value)
    }
  })
  console.log(dataInfo, 'dataInfo')
  return Object.values(dataInfo)
}

export const subSet = (arr1, arr2) => {
  let arr3 = arr2.filter(function(v) {
    return arr1.indexOf(v) !== -1 // 利用filter方法来遍历是否有相同的元素
  })
  let result = arr2.concat(arr3).filter(function(v) {
    return arr2.indexOf(v) === -1 || arr3.indexOf(v) === -1
  })
  return result
}

export const permissionEditOrDelete = (data, type, name) => {
  const a = [
    `客户管理CRM-${type}-我负责的-${name}-本人创建`,
    `客户管理CRM-${type}-我负责的-${name}-他人创建`
  ]
  const b = Store.state.permission.permission
  const combine = a.filter(v => b.includes(v)) // 交集
  const loginUser = Store.state.user.accountInfo.followName
  const currentUser = data.createUserName
  if (combine.length == 2) {
    return true
  } else if (combine.length == 1) {
    if (combine[0] == `客户管理CRM-${type}-我负责的-${name}-本人创建`) {
      if (currentUser == loginUser) {
        return true
      } else {
        Message.error(`您暂无权限${name}`)
        return false
      }
    } else {
      if (currentUser == loginUser) {
        Message.error(`您暂无权限${name}`)
        return false
      } else {
        return true
      }
    }
  } else {
    Message.error(`您暂无权限${name}`)
    return false
  }
}

//echarts 循环展示
export const autoHover = (myChart, option, num = 5, time = 3000) => {
  let count = 0
  let timeTicket = 0
  // 清除定时器
  function clearLoop() {
    if (timeTicket) {
      clearInterval(timeTicket)
      timeTicket = 0
    }
    myChart.off('mousemove', stopAutoShow)
    myChart.getZr().off('mousemove', zRenderMouseMove)
  }

  // 关闭轮播
  function stopAutoShow() {
    if (timeTicket) {
      clearInterval(timeTicket)
      timeTicket = 0
    }
  }

  function zRenderMouseMove(param) {
    if (param.event) {
      // 阻止canvas上的鼠标移动事件冒泡
      // param.event.cancelBubble = true;
    }

    stopAutoShow()
  }

  timeTicket && clearInterval(timeTicket)
  timeTicket = setInterval(function() {
    myChart.dispatchAction({
      type: 'downplay',
      seriesIndex: 0 // serieIndex的索引值   可以触发多个
    })
    myChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0,
      dataIndex: count
    })
    myChart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: count
    })
    count++
    if (count >= num) {
      count = 0
    }
  }, time)
  myChart.on('mouseover', function(params) {
    clearInterval(timeTicket)
    myChart.dispatchAction({
      type: 'downplay',
      seriesIndex: 0
    })
    myChart.dispatchAction({
      type: 'highlight',
      seriesIndex: 0,
      dataIndex: params.dataIndex
    })
    myChart.dispatchAction({
      type: 'showTip',
      seriesIndex: 0,
      dataIndex: params.dataIndex
    })
  })

  myChart.on('mouseout', function() {
    timeTicket && clearInterval(timeTicket)
    timeTicket = setInterval(function() {
      myChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0 // serieIndex的索引值   可以触发多个
      })
      myChart.dispatchAction({
        type: 'highlight',
        seriesIndex: 0,
        dataIndex: count
      })
      myChart.dispatchAction({
        type: 'showTip',
        seriesIndex: 0,
        dataIndex: count
      })
      count++
      if (count >= num) {
        count = 0
      }
    }, time)
  })
  return {
    clearLoop
  }
}

export const getPageTitle = pageTitle => {
  const title = defaultSettings.title || 'Vue Admin Template'
  if (pageTitle) {
    return `${pageTitle} - ${title}`
  }
  return `${title}`
}

// 进入详情页面
export const enterCompanyDetail = (
  data,
  scrollList,
  activeIndex = 0,
  packageId = 0,
  ...restParams
) => {
  const companyUuid = data?.companyUuid || data?.company_uuid
  localStorage.setItem('scrollList', JSON.stringify(scrollList))
  const params = { companyUuid, activeIndex, packageId }
  Object.assign(params, ...restParams)
  router.push({
    path: '/keydetail/keydetail',
    query: { ...params }
  })
}

export const brandCustomCharts = (topData, bottomData, labelData) => {
  let itemStyle = {
    normal: {},
    emphasis: {
      barBorderWidth: 1,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: 'rgba(0,0,0,0.5)'
    }
  }
  let option = {
    // 基础配置
    // backgroundColor: '#eee',
    tooltip: {
      show: true
    },
    legend: {
      // 图例
      data: ['积极', '消极'], // 图例 记得和series中的name要和这里保持一致
      align: 'left', // 设置图例位置
      left: 10 // 调整图例距离右边的距离
    },
    xAxis: {
      data: labelData // x轴数据
    },
    yAxis: {
      inverse: false,
      splitArea: { show: false },
      splitLine: {
        show: false
      }
    },
    grid: {
      left: 100,
      top: '25%'
    },
    series: [
      {
        name: '积极',
        type: 'bar',
        //  都为正就是堆叠柱状图，一个为正一个为负则为双向柱状图
        stack: 'one',
        itemStyle: itemStyle,
        color: '#41cabf',
        barWidth: 20,
        data: topData
      },
      {
        name: '消极',
        type: 'bar',
        stack: 'one',
        itemStyle: itemStyle,
        color: '#f00',
        barWidth: 20,
        data: bottomData
      }
    ]
  }

  return option
}

export const handleCopyFn = value => {
  function selectText(oInput) {
    if (oInput.createTextRange) {
      oInput.select()
    } else {
      oInput.setSelectionRange(0, oInput.value.length)
      oInput.focus()
    }
  }
  event.stopPropagation()
  const oInput = document.createElement('input')
  oInput.value = value
  document.body.appendChild(oInput)
  selectText(oInput)
  document.execCommand('Copy')
  oInput.className = 'oInput'
  oInput.style.display = 'none'

  Message.success('复制成功')
}

export const validateName = str => {
  // 使用正则表达式匹配中文字符
  // 注意：这个正则表达式只考虑了BMP中的常用中文字符范围
  return /[\u4e00-\u9fff]/.test(str)
}

export const validatePassword = password => {
  if (!password) {
    return '请输入密码'
  }

  // 长度检查
  if (password.length < 8 || password.length > 16) {
    return '长度为8-16个字符'
  }

  // 检查是否包含空格
  if (/\s/.test(password)) {
    return '密码不能包含空格'
  }

  // 检查是否至少包含字母、数字、符号中的2种
  let hasLetter = /[a-zA-Z]/.test(password)
  let hasNumber = /[0-9]/.test(password)
  let hasSymbol = /[!@#$%^&*(),.?":{}|<>]/.test(password) // 示例符号集，可以根据需要调整
  let countTypes =
    (hasLetter ? 1 : 0) + (hasNumber ? 1 : 0) + (hasSymbol ? 1 : 0)
  if (countTypes < 2) {
    return '密码必须包含字母、数字、符号中至少2种'
  }

  // 检查数字连续6位及以上
  if (/([0-9])\1{5,}/.test(password)) {
    return '不能包含连续6位及以上的数字'
  }

  // 检查字母连续6位及以上（不区分大小写）
  if (/([a-z])\1{5,}/i.test(password)) {
    return '不能包含连续6位及以上的字母'
  }

  return ''
}

export const handleIsAuth = (isAuth, url) => {
  const token = Store.state.permission.token
  //登录
  if (isAuth == 0) {
    if (!token) {
      router.replace(`/login?path=${url}`)
      return false
    } else {
      return true
    }
  }

  //免登录
  if (isAuth == 1) {
    return true
  }
}

export const saveXls = (data, fileName) => {
  function formatDate() {
    const date = new Date()
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0') // 补零
    const day = String(date.getDate()).padStart(2, '0') // 补零
    const hours = String(date.getHours()).padStart(2, '0') // 补零
    const minutes = String(date.getMinutes()).padStart(2, '0') // 补零
    const seconds = String(date.getSeconds()).padStart(2, '0') // 补零
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
  }

  const urlObject = window.URL || window.webkitURL || window
  const export_blob = new Blob([data])
  //createElementNS() 方法可创建带有指定命名空间的元素节点。
  //此方法可返回一个 Element 对象。
  const save_link = document.createElementNS(
    'http://www.w3.org/1999/xhtml',
    'a'
  )
  const fileList = fileName.split('.')
  const downLoadName = `${fileList[0]}(${formatDate()}).${fileList[1]}`
  save_link.href = urlObject.createObjectURL(export_blob)
  save_link.download = downLoadName // + ".png";
  save_link.click()
}

export const peAxios = async ({ url, method, params }) => {
  const base = process.env.VUE_APP_BASE_API
  const token = Store.state.permission.token
  const headers = { Authorization: 'Bearer ' + token }
  return await http[method](base + url, params, headers)
}
